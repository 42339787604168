import { NotificationManager } from "../components/react-notifications";
import { defaultDirection } from "../constants/defaultValues";

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction");
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("gogo_current_user") != null
        ? JSON.parse(localStorage.getItem("gogo_current_user") || "")
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user: any) => {
  try {
    if (user) {
      localStorage.setItem("gogo_current_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("gogo_current_user");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};

export const showNotification = (
  message: string,
  pageName: string,
  time = 30000,
  shape = "rounded",
  type = "Success"
) => {
  if (type === "Success")
    NotificationManager.success(message, pageName, time, null, null, shape);
  else NotificationManager.error(message, pageName, time, null, null, shape);
};
