export const adminRoot = "/app";
export const memberRoot = "/member";
export const UserRole = {
  SuperAdmin: 1,
  Admin: 2,
  Member: 4,
};

export const isAuthGuardActive = false;
export const defaultDirection = "ltr";
export const defaultMenuType = "menu-default";
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const currentUser = {
  id: 1,
  title: "Sarah Kortney",
  img: "/assets/img/profiles/l-1.jpg",
  date: "Last seen today 15:24",
  role: UserRole.Admin,
};
