import axios, { AxiosResponse } from "axios";
import { User, Users, authUser } from "../types/users";
import {
  Agent,
  AgentContactDto,
  AgentDetails,
  AgentRole,
  Agents,
  CreateAgent,
} from "../types/agents";
import {
  CreateParentPage,
  CreateSubPage,
  PageTypes,
  Pages,
  ParentPageDetails,
  ParentPages,
  SubPages,
} from "../types/pages";
import {
  Member,
  Members,
  MemberDetails,
  CreateMember,
  MemberAddressDto,
  MemberContactDto,
  MemberRole,
} from "../types/members";
import { Membership, MembershipList, Memberships } from "../types/memberships";
import { Dashboard } from "../types/dashboard";
import { States, Roles } from "../types/metadata";
import Locales from "../lang/locales/en_US";
import { EmailCheck } from "../types/common";
import { PageDetails } from "../types/member/PageDetails";
import { MembershipInfo } from "../types/member/MembershipInfo";
import { MembershipDetails } from "../types/member/MembershipDetails";
import {
  AdminDashboard,
  DashboardDetails,
  DashboardList,
} from "../types/dashboards";
import { MemberDashboardDataDetails } from "../types/member/MemberDashboard";

axios.defaults.baseURL = process.env.REACT_APP_Base_URL;

axios.interceptors.request.use((config) => {
  const tokenString = sessionStorage.getItem("token");
  if (tokenString) {
    const userToken = JSON.parse(tokenString);
    if (userToken && config.headers) config.headers.Authorization = userToken;
  }
  return config;
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  activate: <T>(url: string) => axios.put<T>(url).then(responseBody),
};

const PageData = {
  list: () => requests.get<Pages>("/Page/getall"),
  pageTypes: () => requests.get<PageTypes>("/Page/getpagetypes"),
  subpagelist: (status = false) =>
    requests.get<SubPages>(`/Page/getallsubpages/${status}`),
  details: (id: string) =>
    requests.get<ParentPageDetails>(`/Page/getPageDetails/${id}`),
  create: (page: CreateSubPage) =>
    requests.post<any>(`/Page/createsubpage`, page),
  update: (page: CreateSubPage) =>
    requests.put<any>(`/Page/updatesubpage`, page),
  delete: (id: string) => requests.delete<any>(`/Page/delete/${id}`),
  activate: (id: string) => requests.activate<any>(`/Page/activate/${id}`),
  checkDuplicateTitle: (title: string) =>
    requests.get<EmailCheck>("/Page/validatesubpageTitle/" + title),
};

const ParentPageData = {
  list: (status = false) =>
    requests.get<ParentPages>(`/Page/getallparentpages/${status}`),
  details: (id: string) =>
    requests.get<ParentPageDetails>(`/Page/getPageDetails/${id}`),
  create: (page: CreateParentPage) =>
    requests.post<any>(`/Page/createparentpage`, page),
  update: (page: CreateParentPage) =>
    requests.put<any>(`/Page/updateparentpage`, page),
  delete: (id: string) => requests.delete<any>(`/Page/delete/${id}`),
  activate: (id: string) => requests.activate<any>(`/Page/activate/${id}`),
  checkDuplicateTitle: (title: string) =>
    requests.get<EmailCheck>("/Page/validateparentpageTitle/" + title),
};

const SubPageData = {
  list: (status = false) =>
    requests.get<SubPages>(`/Page/getallsubpages/${status}`),
};

const AgentsData = {
  list: (status = false) => requests.get<Agents>(`/Agent/getall/${status}`),
  details: (id: string) => requests.get<AgentDetails>(`/Agent/get/${id}`),
  create: (agent: CreateAgent) => requests.post<Agent>(`/Agent/create`, agent),
  update: (agent: CreateAgent) => requests.put<Agent>(`/Agent/update`, agent),
  delete: (id: string) => requests.delete<any>(`/Agent/delete/${id}`),
  activate: (id: string) => requests.activate<any>(`/Agent/activate/${id}`),
};

const DashboardsData = {
  list: (status = false) =>
    requests.get<DashboardList>(`/Dashboard/getall/${status}`),
  details: (id: string) =>
    requests.get<DashboardDetails>(`/Dashboard/get-dashboard/${id}`),
  create: (dashboard: AdminDashboard) =>
    requests.post<AdminDashboard>(`/Dashboard/create`, dashboard),
  update: (dashboard: AdminDashboard) =>
    requests.put<AdminDashboard>(`/Dashboard/update`, dashboard),
  delete: (id: string, newId: string) =>
    requests.delete<any>(`/Dashboard/delete/${id}/${newId}`),
  activate: (id: string) => requests.activate<any>(`/Dashboard/activate/${id}`),
  listbyAgent: (agentId: number) =>
    requests.get<MembershipList>(`/Dashboard/get-agentdashboard/${agentId}`),
};

const AgentsContactsData = {
  create: (agent: AgentContactDto) =>
    requests.post<AgentContactDto>(`/AgentContact/create`, agent),
  list: () => requests.get<AgentContactDto>("/AgentContact/getall"),
  details: (id: string) =>
    requests.get<AgentContactDto>(`/AgentContact/get/${id}`),
  update: (member: AgentContactDto) =>
    requests.put<AgentContactDto>(`/AgentContact/update`, member),
  delete: (id: number) => requests.delete<void>(`/AgentContact/delete/${id}`),
  rolesList: () => requests.get<AgentRole>(`/AgentRole/getall`),
};

const MembersData = {
  list: (status = false) => requests.get<Members>(`/Member/getall/${status}`),
  details: (id: string) => requests.get<MemberDetails>(`/Member/get/${id}`),
  create: (member: CreateMember) =>
    requests.post<Member>(`/Member/create`, member),
  bulkMail: (welcomeMail: boolean, resetPassword: boolean, memberIds: any) =>
    requests.post<any>(
      `/Member/bulk-mail/${welcomeMail}/${resetPassword}`,
      memberIds
    ),
  update: (member: CreateMember) =>
    requests.put<Member>(`/Member/update`, member),
  delete: (id: string) => requests.delete<void>(`/Member/delete/${id}`),
  activate: (id: string) => requests.activate<void>(`/Member/activate/${id}`),
  info: () => requests.get<MembershipInfo>(`/Member/get-memshipinfo`),
  accountDetails: () =>
    requests.get<MembershipDetails>(`/Member/get-memberdetails`),
  dashboardData: () =>
    requests.get<MemberDashboardDataDetails>(`/Member/get-memdashboard`),
};

const MemberContactData = {
  list: (memberid: number) =>
    requests.get<MemberContactDto>(`/MemberContact/getall/${memberid}`),
  details: (membercontactId: number) =>
    requests.get<MemberContactDto>(`/MemberContact/get/${membercontactId}`),
  create: (member: MemberContactDto) =>
    requests.post<MemberContactDto>(`/MemberContact/create`, member),
  update: (member: MemberContactDto) =>
    requests.put<MemberContactDto>(`/MemberContact/update`, member),
  delete: (id: number) => requests.delete<void>(`/MemberContact/delete/${id}`),
  rolesList: () => requests.get<MemberRole>(`/MemberRole/getall`),
};

const MemberAddressData = {
  list: (memberid: number) =>
    requests.get<MemberAddressDto>(`/MemberAddress/getall/${memberid}`),
  details: (memberaddressId: number) =>
    requests.get<MemberAddressDto>(`/MemberAddress/get/${memberaddressId}`),
  create: (member: MemberAddressDto) =>
    requests.post<MemberAddressDto>(`/MemberAddress/create`, member),
  update: (member: MemberAddressDto) =>
    requests.put<MemberAddressDto>(`/MemberAddress/update`, member),
  delete: (memberaddressId: number) =>
    requests.delete<void>(`/MemberAddress/delete/${memberaddressId}`),
};

const MembershipsData = {
  list: (status = false) =>
    requests.get<MembershipList>(`/Membership/getall/${status}`),
  listbyAgent: (agentId: number) =>
    requests.get<MembershipList>(`/Membership/getagent/${agentId}`),
  pagesbyMembership: (membershipId: number) =>
    requests.get<PageDetails>(`/Membership/getpageDetails/${membershipId}`),
  details: (id: number) => requests.get<Memberships>(`/Membership/get/${id}`),
  create: (membership: Membership) =>
    requests.post<Membership>(`/Membership/create`, membership),
  update: (membership: Membership) =>
    requests.put<Membership>(`/Membership/update`, membership),
  delete: (id: string, newId: string) =>
    requests.delete<void>(`/Membership/delete/${id}/${newId}`),
  activate: (id: number) =>
    requests.activate<void>(`/Membership/activate/${id}`),
};
const UsersData = {
  list: (status = false) => requests.get<Users>(`/User/getall/${status}`),
  details: (id: string) => requests.get<Users>(`/User/get/${id}`),
  create: (user: User) => requests.post<User>(`/User/create`, user),
  update: (user: User) => requests.put<User>(`/User/update`, user),
  delete: (id: number) => requests.delete<User>(`/User/delete/${id}`),
  activate: (id: number) => requests.activate<void>(`/User/activate/${id}`),
};

const DashboardData = {
  list: () => requests.get<Dashboard>("/Dashboard/get"),
};

const UploadCertificate = {
  upload: (file: File) => requests.post<any>("/Certification/uploadpdf", file),
  create: (data: any) => requests.post<any>("/Certification/create", data),
  get: () => requests.get<any>("Certification/get-processedpdf"),
};

const CheckEmail = {
  list: (email: string, status = false) =>
    requests.get<EmailCheck>("/User/check/" + email + "/" + status),
};

const CheckTitle = {
  list: (title: string) =>
    requests.get<EmailCheck>("/Membership/validateTitle/" + title),
};

const CheckDashboardTitle = {
  list: (title: string) =>
    requests.get<EmailCheck>("/Dashboard/validateTitle/" + title),
};

const MetaData = {
  sportlist: () => requests.get<States>("/State/getall"),
  rolelist: () => requests.get<Roles>("/Role/getall"),
};

const AccountsData = {
  forgotEmailCheck: (email: string) =>
    requests.get<any>("/Account/forgot-password/" + email),
  resetPassword: (newPassword: string, resetPasswordRequestUniqueId: string) =>
    requests.put<any>("/Account/resetpassword", {
      oldPassword: "",
      newPassword,
      resetPasswordRequestUniqueId,
    }),
  changePassword: (oldPassword: string, newPassword: string) =>
    requests.put<any>("/Account/change-password", {
      oldPassword,
      newPassword,
      resetPasswordRequestUniqueId: "",
    }),
};

const service = {
  PageData,
  ParentPageData,
  AgentsData,
  DashboardsData,
  MembersData,
  MembershipsData,
  UsersData,
  DashboardData,
  AgentsContactsData,
  MemberContactData,
  MemberAddressData,
  MetaData,
  CheckEmail,
  SubPageData,
  CheckTitle,
  UploadCertificate,
  AccountsData,
  CheckDashboardTitle,
};

export default service;

// export const baseUrl = "https://localhost:7250";
// export const token = UseToken();

// export const GetAgents = (): Promise<Agents> => {
//   const response = axios
//     .get(`${baseUrl}/api/Agent/getall`, {
//       headers: { Authorization: token.token },
//     })
//     .then((res) => {
//       return res.data;
//     });
//   return response;
// };

export const authenticateUser = ({
  userName,
  password,
}: authUser): Promise<any> => {
  const body = `${userName}_${password}`;
  //   const token = UseToken();
  const response = axios
    .post("/Account/issue-token", body, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res: any) => {
      console.log("middleware respnse", res);
      return res.data;
    });
  return response;
};

export const authenticateSSOUser = (code: any): Promise<any> => {
  //   const token = UseToken();
  const response = axios
    .post("/Account/issue-sso-token", code, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res: any) => {
      console.log("middleware respnse", res);
      return res.data;
    });
  return response;
};

export const CheckSession = (res: any, history: any) => {
  if (
    res.statusCode === 500 &&
    res.error.customMessage === Locales.user.sessionExpired
  ) {
    history.push("/login");
  }
};
