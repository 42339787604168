import "./index.css";
import "./assets/css/vendor/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-tabs/style/react-tabs.scss";
import "video.js/dist/video-js.css";

const color = "light.purplemonster";

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require("./AppRenderer");
  });
};
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
