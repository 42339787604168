var Locales = {
  menu: {
    dashboard: "Dashboard",
    pageManagement: "Page Management",
    agentManagement: "Agency Management",
    dashboardManagement: "Dashboard Management",
    memberManagement: "Member Management",
    membershipManagement: "Membership Management",
    userManagement: "User Management",
    createSubPage: "Create Sub Page",
    createParentPage: "Create Parent Page",
    pages: "Sub Pages",
    parentPages: "Parent Pages",
    createAgent: "Create Agency",
    agents: " Agencies",
    createDashboard: "Create Dashboard",
    dashboards: " Dashboards",
    createMember: "Create Member",
    members: " Members",
    createMembership: "Create Membership",
    memberships: " Memberships",
    createUser: "Create User",
    users: " Users",
  },
  user: {
    sessionExpired: "Session expired. Please try logging in again.",
  },
};

export default Locales;
